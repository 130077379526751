import { configureStore } from '@reduxjs/toolkit';
import workerSliceReducer from '../features/WorkerRegistrationSlice';
import authSliceReducer from '../features/AuthSlice';
import adminReducer from '../features/AdminSlice';
import { locationReducer } from '../features/locationSlice';
import PaymentMethodSlice from '../features/PaymentMethod';
import trackingReducer from '../features/TrackingFori';
import bookingSlice from '../features/Booking';

export const store = configureStore({
  reducer: {
    worker: workerSliceReducer,
    auth: authSliceReducer,
    location: locationReducer,
    admin: adminReducer,
    tracking: trackingReducer,
    paymentMethod: PaymentMethodSlice,
    booking: bookingSlice,
  },
});