import { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

function Spinner() {
  let [loading] = useState(true);
  let [color] = useState("#12333a");

  return (
    <div
      className="sweet-loading"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {loading && <PuffLoader color={color} speedMultiplier={1} />}
    </div>
  );
}

export default Spinner;