import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";

const initialState = {
    allSkills: [],
    workersByPageNum: [],
    totalWorkers: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};








////////////////////Get All Recommended Skills////////////////////////
export const getAllSkills = createAsyncThunk(
    "Admin/getAllSkills",
    async (_, thunkAPI) => {
        try {
            const API_URL = `${process.env.REACT_APP_SERVER_URL}/admin/getAllSkills`;
            const response = await axios.get(API_URL);
            console.log(response.data,'response.data');
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.status ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);



////////////////////Add A Skill Admin////////////////////////
export const addASkillAdmin = createAsyncThunk(
    "Worker/addASkillAdmin",
    async (formData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            const API_URL = `${process.env.REACT_APP_SERVER_URL}/admin/addNewSkills`;
            const response = await axios.patch(API_URL, formData, config);
            return response.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);








////////////////////Delete A Skill Admin////////////////////////
export const deleteASkillAdmin = createAsyncThunk(
    "Worker/deleteASkillAdmin",
    async (skillId, thunkAPI) => {
        console.log(skillId, 'skillId');
        try {
            const token = thunkAPI.getState().auth.user.token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            const API_URL = `${process.env.REACT_APP_SERVER_URL}/admin/deleteSingleSkill/${skillId}`;
            const response = await axios.delete(API_URL, config);
            return response.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

////////////////////Delete A Worker////////////////////////
export const deleteAWorker = createAsyncThunk(
    "Worker/deleteAWorker",
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            const API_URL = `${process.env.REACT_APP_SERVER_URL}/admin/deleteWorkerByAdmin/${id}`;
            const response = await axios.delete(API_URL, config);
            return response.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


////////////////////get Workers By Page Number////////////////////////
export const getWorkersByPageNum = createAsyncThunk(
    "Worker/getWorkersByPageNum",
    async (PageNum, thunkAPI) => {
        try {
            const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getWorkersByPageNum/${PageNum}`;
            const response = await axios.get(API_URL);
            return response.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);






export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
            /////////////Get All Workers/////////////
            .addCase(getAllSkills.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllSkills.fulfilled, (state, action) => {
                if (action.payload.success === true) {
                    state.allSkills = action.payload.skills;
                }
                state.isLoading = false;
                state.isSuccess = true;
            })
            .addCase(getAllSkills.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                toast(action.payload.message);
            })
            /////////////Add A Skill Admin//////////////
            .addCase(addASkillAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addASkillAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
                toast(action.payload.message);
            })
            .addCase(addASkillAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                toast.error(action.payload.message);
            })
            /////////////Delete A Skill Admin//////////////
            .addCase(deleteASkillAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteASkillAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
                toast(action.payload.message);
            })
            .addCase(deleteASkillAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                toast.error(action.payload.message);
            })
            /////////////Delete A Worker//////////////
            .addCase(deleteAWorker.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteAWorker.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;

            })
            .addCase(deleteAWorker.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                toast.error(action.payload.message);
            })
            /////////////Get Workers By Page Number//////////////
            .addCase(getWorkersByPageNum.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getWorkersByPageNum.fulfilled, (state, action) => {
                if (action.payload.success === true) {
                    state.workersByPageNum = action.payload.workers;
                    state.totalWorkers = action.payload.totalWorkers;
                }
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
            })
            .addCase(getWorkersByPageNum.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                toast.error(action.payload.message);
            })


    },
});

export const { reset } = adminSlice.actions;
export default adminSlice.reducer;
