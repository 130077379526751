import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";

const initialState = {
   allWorkers: [],
   allRecommendedSkills: [],
   requiredWorker: null,
   topRatedWorkerProfiles: [],
   phoneVerificationResponse: '',
   phoneVerificationResponseAddWorker: '',
   phoneVerificationResponseForUpdatingWorker: '',
   skillsWithCount: '',
   workersBySkill: '',
   workersCountBySkill: '',
   workersBySkillAndPageNumber: [],
   totalWorkersBySkill: 0,
   isError: false,
   isSuccess: false,
   isLoading: false,
   message: "",
   location: localStorage.getItem('location') || "",
   contactDetails: {},
};




//////////////////////Register Worker///////////////////////////
export const registerWorker = createAsyncThunk(
   "Worker/registerWorker",
   async (formData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/registerWorker`;
         const response = await axios.post(API_URL, formData, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);





//////////////////////Add a Worker///////////////////////////
export const addAWorker = createAsyncThunk(
   "Worker/addAWorker",
   async (formData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/addAWorker`;
         const response = await axios.post(API_URL, formData, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);






//////////////////////Phone Verification Status///////////////////////////
export const phoneVerificationStatus = createAsyncThunk(
   "Worker/phoneVerificationStatus",
   async (phone, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/phoneVerificationStatus`;
         const response = await axios.post(API_URL, phone, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);






//////////////////////Phone Verification Status///////////////////////////
export const phoneVerificationAndCreatingUser = createAsyncThunk(
   "Worker/phoneVerificationAndCreatingUser",
   async ({ data }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/phoneVerificationAndCreatingUser`;
         const response = await axios.post(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);








//////////////////////Phone Verification For Updating Worker///////////////////////////
export const phoneVerificationForUpdatingWorker = createAsyncThunk(
   "Worker/phoneVerificationForUpdatingWorker",
   async ({ data, workerId }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/phoneVerificationForUpdatingWorker/${workerId}`;
         const response = await axios.post(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);





//////////////////////Verify Phone With Verification Code///////////////////////////
export const verifyPhoneWithVerificationCode = createAsyncThunk(

   "Worker/verifyPhoneWithVerificationCode",


   async (verifyData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/verifyPhoneWithVerificationCode`;
         const response = await axios.post(API_URL, verifyData, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);






//////////////////////Resend Verification code///////////////////////////
export const resendVerificationCode = createAsyncThunk(
   "Worker/resendVerificationCode",
   async (token, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/resendVerificationCode`;
         const response = await axios.post(API_URL, token, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);








//////////////////////Register Multiple Workers///////////////////////////
export const registerMultipleWorkers = createAsyncThunk(
   "Worker/registerMultipleWorkers",
   async (formData, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/registerMultipleWorkers`;
         const response = await axios.post(API_URL, formData, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);








//////////////////////Verify Worker Email///////////////////////////
export const verifyWorkerEmail = createAsyncThunk(
   "Worker/verifyWorkerEmail",
   async (token, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/verifyWorkerEmail/${token}`;
         const response = await axios.patch(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);




////////////////////Invite Worker Friends////////////////////////
export const inviteWorkerFriends = createAsyncThunk(
   "Worker/inviteWorkerFriends",
   async ({ workerId, friendsInfo }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/inviteWorkerFriends/${workerId}`;
         const response = await axios.patch(API_URL, friendsInfo, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);



//////////////////////Verify Worker By Friend///////////////////////////
export const verifyWorkerByFriend = createAsyncThunk(
   "Worker/VerifyWorkerByFriend",
   async ({ workerId, phone }, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/verifyWorkerByFriend/${workerId}`;
         const response = await axios.patch(API_URL, { phone });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);





////////////////////Get Single Worker////////////////////////
export const getSingleWorker = createAsyncThunk(
   "Worker/getSingleWorker",
   async (workerId, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getSingleWorker/${workerId}`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);






////////////////////Top Rated Workers////////////////////////
export const topRatedWorkers = createAsyncThunk(
   "Worker/topRatedWorkers",
   async (_, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/topRatedWorkers`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);





////////////////////Get All Workers////////////////////////
export const getAllWorkers = createAsyncThunk(
   "Worker/getAllWorkers",
   async (_, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getAllWorkers`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);








////////////////////All skills With Count////////////////////////
export const allSkillsWithCount = createAsyncThunk(
   "Worker/allSkillsWithCount",
   async (_, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/allSkillsWithCount`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);








////////////////////getWorkersBySkill////////////////////////
export const getWorkersBySkill = createAsyncThunk(
   "Worker/getWorkersBySkill",
   async (skill, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getWorkersBySkill/${skill}`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);










////////////////////Update Worker Views////////////////////////
export const updateWorkerViews = createAsyncThunk(
   "Worker/updateWorkerViews",
   async ({ workerId, workerProfileViews }, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/updateWorkerViews/${workerId}`;
         const response = await axios.patch(API_URL, { workerProfileViews });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);





////////////////////Update Worker Reviews////////////////////////
export const updateWorkerReviews = createAsyncThunk(
   "Worker/updateWorkerReviews",
   async ({ workerId, reviews }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/updateWorkerReviews/${workerId}`;
         const response = await axios.patch(API_URL, reviews, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);






////////////////////Update Single Worker////////////////////////
export const updateSingleWorker = createAsyncThunk(
   "Worker/updateSingleWorker",
   async ({ workerId, data }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/updateSingleWorker/${workerId}`;
         const response = await axios.patch(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);






////////////////////Activate Or Deactivate Worker////////////////////////
export const activateOrDeactivateWorker = createAsyncThunk(
   "Worker/activateOrDeactivateWorker",
   async ({ workerId, data }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/activateOrDeactivateWorker/${workerId}`;
         const response = await axios.patch(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// activate Phone Number or Deactivate Phone Number
export const updateContactDetailsPrivacy = createAsyncThunk(
   "Worker/updateContactDetailsPrivacy",
   async ({ workerId, data }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/updateContactDetailsPrivacy/${workerId}`;
         const response = await axios.patch(API_URL, data, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//get Contact Details
export const getContactDetails = createAsyncThunk(
   "Worker/getContactDetails",
   async (workerId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getContactDetails/${workerId}`;

         const response = await axios.get(API_URL, config);
         console.log(response.data);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||

            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);





////////////////////Delete Single Worker////////////////////////
export const deleteSingleWorker = createAsyncThunk(
   "Worker/deleteSingleWorker",
   async (workerId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/deleteSingleWorker/${workerId}`;
         const response = await axios.delete(API_URL, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);









//////////////////////Recommend A Skill///////////////////////////
export const recommendSkill = createAsyncThunk(
   "Worker/recommendSkill",
   async ({ skillName }, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         }

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/recommendSkill`;
         const response = await axios.post(API_URL, { skillName }, config);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);









////////////////////Get All Recommended Skills////////////////////////
export const getAllRecommendSkills = createAsyncThunk(
   "Worker/getAllRecommendSkills",
   async (_, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getAllRecommendSkills`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


////////////////////Get Workers by Skill and Page Number////////////////////////
export const getWorkersBySkillAndPageNumber = createAsyncThunk(
   "Worker/getWorkersBySkillAndPageNumber",
   async ({ skill, PageNum, lat, lng }, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getWorkersBySkillByPageNum/${skill}/${PageNum}?lat=${encodeURIComponent(lat)}&lng=${encodeURIComponent(lng)}`;
         const response = await axios.get(API_URL);

         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);









////////////////////Worker Count by skill////////////////////////
export const getWorkersCountBySkill = createAsyncThunk(
   "Worker/getWorkersCountBySkill",
   async (skill, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/worker/getWorkerCountBySkill/${skill}`;
         const response = await axios.get(API_URL);
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);











export const workerSlice = createSlice({
   name: "worker",
   initialState,
   reducers: {
      reset: (state) => {
         state.isLoading = false;
         state.isSuccess = false;
         state.isError = false;
         state.message = "";
      },
   },
   extraReducers: (builder) => {
      builder
         /////////////Register Worker//////////////
         .addCase(registerWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(registerWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(registerWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = action.payload.success;
            state.message = action.payload;
            toast.error(action.payload.message || action.payload)
         })
         /////////////Add a Worker//////////////
         .addCase(addAWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(addAWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(addAWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = action.payload.success;
            state.message = action.payload;
            toast.error(action.payload.message || action.payload)
         })
         /////////////Phone Verification Status//////////////
         .addCase(phoneVerificationStatus.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(phoneVerificationStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            state.phoneVerificationResponse = action.payload.message || action.payload
            // toast(action.payload.message || action.payload)
         })
         .addCase(phoneVerificationStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            state.phoneVerificationResponse = action.payload.message || action.payload
            // toast.error(action.payload.message || action.payload)
         })
         /////////////Phone Verification Status And Creating User//////////////
         .addCase(phoneVerificationAndCreatingUser.pending, (state) => {
            state.isLoading = true;
         })

         .addCase(phoneVerificationAndCreatingUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            state.phoneVerificationResponseAddWorker = action.payload.message || action.payload
            // toast(action.payload.message || action.payload)
         })
         .addCase(phoneVerificationAndCreatingUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            state.phoneVerificationResponseAddWorker = action.payload.message || action.payload
            // toast.error(action.payload.message || action.payload)
         })
         /////////////Phone Verification For Updating Worker//////////////
         .addCase(phoneVerificationForUpdatingWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(phoneVerificationForUpdatingWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            state.phoneVerificationResponseForUpdatingWorker = action.payload.message || action.payload
            // toast(action.payload.message || action.payload)
         })
         .addCase(phoneVerificationForUpdatingWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            state.phoneVerificationResponseForUpdatingWorker = action.payload.message || action.payload
            // toast.error(action.payload.message || action.payload)
         })
         /////////////Verify Phone With Verification Code//////////////
         .addCase(verifyPhoneWithVerificationCode.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(verifyPhoneWithVerificationCode.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(verifyPhoneWithVerificationCode.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            toast.error(action.payload.message)
         })
         /////////////Resend Verification code//////////////
         .addCase(resendVerificationCode.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(resendVerificationCode.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(resendVerificationCode.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            toast.error(action.payload.message)
         })
         /////////////Register Multiple Workers//////////////
         .addCase(registerMultipleWorkers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(registerMultipleWorkers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            // toast(action.payload.message);
         })
         .addCase(registerMultipleWorkers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = action.payload.success;
            state.message = action.payload;
            toast.error(action.payload)
            // toast.error(action.payload);
         })
         /////////////Verify Worker Email//////////////
         .addCase(verifyWorkerEmail.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(verifyWorkerEmail.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(verifyWorkerEmail.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            toast.error(action.payload.message)
         })
         /////////////Invite Worker Friends//////////////
         .addCase(inviteWorkerFriends.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(inviteWorkerFriends.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(inviteWorkerFriends.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            toast.error(action.payload.message || action.payload);
         })
         /////////////Verify Worker by Friend//////////////
         .addCase(verifyWorkerByFriend.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(verifyWorkerByFriend.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message || action.payload);
         })
         .addCase(verifyWorkerByFriend.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message
            toast.error(action.payload.message || action.payload)
         })
         /////////////Get Single Worker//////////////
         .addCase(getSingleWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getSingleWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.requiredWorker = action.payload.foundWorker;
            state.message = action.payload.message;
            // toast(action.payload.message)
         })
         .addCase(getSingleWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.requiredWorker = null;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         /////////////Top Rated Worker//////////////
         .addCase(topRatedWorkers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(topRatedWorkers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.topRatedWorkerProfiles = action.payload.foundWorkers;
            state.message = action.payload.message;
            // toast(action.payload.message)
         })
         .addCase(topRatedWorkers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.requiredWorker = null;
            state.message = action.payload.message;
            // toast(action.payload.message);
         })
         /////////////Get All Workers/////////////
         .addCase(getAllWorkers.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAllWorkers.fulfilled, (state, action) => {
            if (action.payload.success === true) {
               state.allWorkers = action.payload.allWorkers;
            }
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(getAllWorkers.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         /////////////all skills With Count/////////////
         .addCase(allSkillsWithCount.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(allSkillsWithCount.fulfilled, (state, action) => {
            if (action.payload.success === true) {
               state.skillsWithCount = action.payload.skillsWithCount;
            }
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(allSkillsWithCount.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            // toast(action.payload.message);
         })
         /////////////all skills With Count/////////////
         .addCase(getWorkersBySkill.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getWorkersBySkill.fulfilled, (state, action) => {
            if (action.payload.success === true) {
               state.workersBySkill = action.payload.workers;
            }
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(getWorkersBySkill.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            // toast(action.payload.message);
         })
         /////////////Update Worker Views//////////////
         .addCase(updateWorkerViews.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateWorkerViews.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
         })
         .addCase(updateWorkerViews.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
         })
         /////////////Update Worker Reviews//////////////
         .addCase(updateWorkerReviews.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateWorkerReviews.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message)
         })
         .addCase(updateWorkerReviews.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload.message || action.payload)
         })
         /////////////Update Single Worker//////////////
         .addCase(updateSingleWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateSingleWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast.success("You have successfully updated your Profile")
         })
         .addCase(updateSingleWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast(action.payload.message || action.payload)
         })
         /////////////Activate Or Deactivate Worker//////////////
         .addCase(activateOrDeactivateWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(activateOrDeactivateWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message)
         })
         .addCase(activateOrDeactivateWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast(action.payload.message || action.payload)
         })
         /////////////Update Content Details Privacy//////////////
         .addCase(updateContactDetailsPrivacy.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateContactDetailsPrivacy.fulfilled, (state, action) => {
            state.isLoading = false;

            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message)
         })
         .addCase(updateContactDetailsPrivacy.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;

         })
         /////////////Get Contact Details//////////////
         .addCase(getContactDetails.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getContactDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.contactDetails = action.payload.contactDetails || action.payload;

         })
         .addCase(getContactDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;

         })
         /////////////Delete Single Worker//////////////
         .addCase(deleteSingleWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(deleteSingleWorker.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
         })
         .addCase(deleteSingleWorker.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload.message || action.payload)
         })
         //////////////Recommend A Skill/////////////
         .addCase(recommendSkill.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(recommendSkill.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         .addCase(recommendSkill.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = action.payload.success;
            state.message = action.payload;
            toast.error(action.payload.message || action.payload)
         })
         /////////////Get All Skills/////////////
         .addCase(getAllRecommendSkills.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getAllRecommendSkills.fulfilled, (state, action) => {
            if (action.payload.success === true) {
               state.allRecommendedSkills = action.payload.allRecommendedSkills;
            }
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(getAllRecommendSkills.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })
         /////////////Get Workers By Skill and Page Number/////////////
         .addCase(getWorkersBySkillAndPageNumber.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getWorkersBySkillAndPageNumber.fulfilled, (state, action) => {
            if (action.payload.success === true) {
               console.log('Updating state with new workers:', action.payload.workers);
               state.workersBySkillAndPageNumber = action.payload.workers;
               state.totalWorkersBySkill = action.payload.totalWorkersBySkill;
            }
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(getWorkersBySkillAndPageNumber.rejected, (state, action) => {

            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            state.workersBySkillAndPageNumber = [];
            state.totalWorkersBySkill = 0;


         })
         /////////////Get Workers Count By Skill/////////////
         .addCase(getWorkersCountBySkill.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getWorkersCountBySkill.fulfilled, (state, action) => {
            if (action.payload.success === true) {
               state.workersCountBySkill = action.payload.workersCountBySkill;
            }
            state.isLoading = false;
            state.isSuccess = true;
         })
         .addCase(getWorkersCountBySkill.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload.message;
            toast(action.payload.message);
         })


   },
});

export const { reset } = workerSlice.actions;
export default workerSlice.reducer;
