import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { toast } from "react-hot-toast";

const initialState = {
   isError: false,
   isSuccess: false,
   isLoading: false,
   paymentMethods: [],
   message: "",
};

export const createPaymentMethod = createAsyncThunk(
   "paymentMethod/createPaymentMethod",
   async (data, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         };
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/attachPaymentMethod`;
         const response = await axios.post(API_URL, data, config);
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.response.data);
      }
   }
);

// Retrieve all Payment Methods
export const retrievePaymentMethods = createAsyncThunk(
   "paymentMethod/retrievePaymentMethods",
   async (customerId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         };
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/retrieveAllPaymentMethods/${customerId}`;
         const response = await axios.get(API_URL, config);
         console.log(response.data, "response.data");
         return response.data;
      } catch (error) {
         const errorMessage = error.response?.data?.message || error.message || "An error occurred";
         return thunkAPI.rejectWithValue(errorMessage);
      }
   }
);

// Detach Payment Method
export const detachPaymentMethod = createAsyncThunk(
   "paymentMethod/detachPaymentMethod",
   async (paymentMethodId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         };
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/detachPaymentMethod`;
         const response = await axios.post(API_URL, paymentMethodId, config);
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.response.data);
      }
   }
);


// Set Default Payment Method
export const setDefaultPaymentMethod = createAsyncThunk(
   "paymentMethod/setDefaultPaymentMethod",
   async (paymentMethodId, thunkAPI) => {
      try {
         const token = thunkAPI.getState().auth.user.token;
         const config = {
            headers: {
               Authorization: `Bearer ${token}`
            }
         };
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/setDefaultPaymentMethod`;
         const response = await axios.post(API_URL, paymentMethodId, config);
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.response.data);
      }
   }
);



export const PaymentMethodSlice = createSlice({
   name: "paymentMethod",
   initialState,
   reducers: {
      clearState: (state) => {
         state.isError = false;
         state.isSuccess = false;
         state.isLoading = false;
         state.message = "";
      },
   },
   extraReducers: {
      [createPaymentMethod.pending]: (state) => {
         state.isLoading = true;
      },
      [createPaymentMethod.fulfilled]: (state, { payload }) => {
         state.isLoading = false;
         state.isSuccess = true;
         state.message = payload.message;
      },
      [createPaymentMethod.rejected]: (state, { payload }) => {
         state.isLoading = false;
         state.isError = true;
         state.message = payload.message;
      },
      [retrievePaymentMethods.pending]: (state) => {
         state.isLoading = true;
      },
      [retrievePaymentMethods.fulfilled]: (state, { payload }) => {
         state.isLoading = false;
         state.isSuccess = true;
         state.paymentMethods = payload.allPaymentMethods;
         state.message = payload.message;
      },
      [retrievePaymentMethods.rejected]: (state, { payload }) => {
         state.isLoading = false;
         state.isError = true;
         state.message = payload.message;

      },
      [detachPaymentMethod.pending]: (state) => {
         state.isLoading = true;
      },
      [detachPaymentMethod.fulfilled]: (state, { payload }) => {
         state.isLoading = false;
         state.isSuccess = true;
         state.message = payload.message;
      },
      [detachPaymentMethod.rejected]: (state, { payload }) => {
         state.isLoading = false;
         state.isError = true;
         state.message = payload.message;
      },
      [setDefaultPaymentMethod.pending]: (state) => {
         state.isLoading = true;
      },
      [setDefaultPaymentMethod.fulfilled]: (state, { payload }) => {
         state.isLoading = false;
         state.isSuccess = true;
         state.message = payload.message;
      },
      [setDefaultPaymentMethod.rejected]: (state, { payload }) => {
         state.isLoading = false;
         state.isError = true;
         state.message = payload.message;
      },

   },
});

export default PaymentMethodSlice.reducer;