import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopWithQuery() {
    const { pathname, search } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, search]); // Now watching both pathname and search query changes

    return null;
}

export default ScrollToTopWithQuery;