import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    location: JSON.parse(localStorage.getItem('location')) || "",
    textual_address: JSON.parse(localStorage.getItem('textual_address')) || "",
};

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            const { location, address } = action.payload;
            const locationString = JSON.stringify(location);
            const addressString = JSON.stringify(address);
            state.location = location;
            state.textual_address = address;
            localStorage.setItem('location', locationString);
            localStorage.setItem('textual_address', addressString);
        },
        updateLocation: (state, action) => {
            state.location = action.payload;
        },
    },
});

export const { setLocation, updateLocation } = locationSlice.actions;
export const locationReducer = locationSlice.reducer;