// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  display: flex;
  flex-direction: column;
  gap: 5rem;

}

.Footer_bottom {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.Footer_bottom>h6 {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

}

.footer_tag {
  font-size: 0.8rem;
}

.followus_links {
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  padding-top: 2rem;

}

.followus_links>* {
  font-size: 1.2rem;
}

.followus_links_upper {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

@media screen and (max-width: 650px) {
  .Footer_bottom {
    flex-direction: column;
    gap: 1rem;
  }

  .followus_links {
    gap: 2rem;
    justify-content: flex-start;
  }

  .followus_links_upper {
    flex-direction: column;
    padding-top: 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;;AAEX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;;AAEjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,yBAAyB;EACzB,iBAAiB;;AAEnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,SAAS;IACT,2BAA2B;EAC7B;;EAEA;IACE,sBAAsB;IACtB,iBAAiB;EACnB;AACF","sourcesContent":[".Footer {\n  display: flex;\n  flex-direction: column;\n  gap: 5rem;\n\n}\n\n.Footer_bottom {\n  display: flex;\n  justify-content: space-between;\n  overflow: hidden;\n}\n\n.Footer_bottom>h6 {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  cursor: pointer;\n\n}\n\n.footer_tag {\n  font-size: 0.8rem;\n}\n\n.followus_links {\n  display: flex;\n  gap: 3rem;\n  justify-content: flex-end;\n  padding-top: 2rem;\n\n}\n\n.followus_links>* {\n  font-size: 1.2rem;\n}\n\n.followus_links_upper {\n  display: flex;\n  justify-content: space-between;\n  padding-top: 2rem;\n}\n\n@media screen and (max-width: 650px) {\n  .Footer_bottom {\n    flex-direction: column;\n    gap: 1rem;\n  }\n\n  .followus_links {\n    gap: 2rem;\n    justify-content: flex-start;\n  }\n\n  .followus_links_upper {\n    flex-direction: column;\n    padding-top: 0rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
